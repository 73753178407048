import moment from 'moment'
import config from '../config/config'
import router from '../router'
import store from '../store'
import { DEFAULT_TIME_FORMAT, ALL_DEFECT } from './constant'

// 获取url前缀，用是否/开头 判断 判断是否完整地址
export function getUrlPrefix(url) {
  if (!url) return null

  if (url.startsWith('/')) {
    return `${location.host}${url}`
  } else {
    // 全地址
    return url
  }
}

// 获取请求的基础链接
export function getBaseUrl() {
  const apiUrl =
      process.env.VUE_APP_API_HOST ||
      getUrlPrefix(window.artemis.config.apiHost),
    baseURL = apiUrl ? `${location.protocol}//${apiUrl}` : ''
  return baseURL
}

// 深拷贝
export function deepCopy(data) {
  if (!data) return null
  return JSON.parse(JSON.stringify(data))
}

// 格式化时间戳
export function formatTimestamp(timestamp, format = DEFAULT_TIME_FORMAT) {
  if (!timestamp) return '-'
  return moment(timestamp * 1).format(format)
}

// 转换为数字格式的时间戳
export function transferToTimestamp(date = new Date()) {
  if (!date) return ''

  const timestamp = moment(date).format('x')
  return timestamp * 1
}

// 防抖
export function debounce(fn, duration = 500) {
  let timer = null

  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, duration)
  }
}

// 节流
export function throttle(fn, duration = 500) {
  let run = true

  return function () {
    if (!run) return
    run = false

    setTimeout(() => {
      fn.apply(this, arguments)
      run = true
    }, duration)
  }
}

// 统一存储的key
export function getStorageKey(key) {
  return `ARTEMIS_APP_${key.toUpperCase()}`
}

// 设置local storage
export function setStorage(key, value) {
  const sKey = getStorageKey(key)
  localStorage[sKey] = value
}

// 获取local storage
export function getStorage(key, parse = false) {
  const sKey = getStorageKey(key),
    sValue = localStorage[sKey]

  if (sValue) {
    return parse ? JSON.parse(sValue) : sValue
  } else {
    return null
  }
}

// 获取默认值
export function getDefaultValue(key, parse = false) {
  return getStorage(key, parse) || (config && config[key] && config[key].value)
}

// 登录跳转
export function routeToLogin(route = router.currentRoute.value) {
  const { redirect } = route.query,
    routerName = route.name

  if (!routerName) return

  if (redirect) {
    router.push({ name: redirect }, () => {})
  } else {
    router.push({ path: '/' }, () => {})
  }
}

// 退出登录
export function routeToLogout(route = router.currentRoute.value) {
  const query = {},
    routerName = route.name

  if (routerName === 'Login') return

  if (routerName !== '403') {
    query.redirect = routerName
  }

  router.push(
    {
      name: 'Login',
      query,
    },
    () => {
      window.location.reload()
    }
  )
}

// agg-event数据整理
export function formatAggEventList(list = []) {
  const eventList = []

  list.forEach((item = {}) => {
    const { docs = [], duration } = item

    // 按照tsUnix倒序
    docs.sort((a = {}, b = {}) => {
      const { data: aData = {} } = a,
        { data: bData = {} } = b

      return bData.tsUnix * 1 - aData.tsUnix * 1
    })

    const latestDoc = docs[0] || {}, // 获取聚合里最新的文档
      { objects = [] } = latestDoc.data || {},
      object = objects[0] || {},
      { attrs = {} } = object

    const newItem = {
      ...latestDoc,
      attrs,
      duration,
    }
    eventList.push(newItem)
  })

  return eventList
}

// event数据整理(ws)
export function formatEventList(list = [], wsEventList = []) {
  const eventList = deepCopy(wsEventList)

  list.forEach((doc = {}) => {
    const { data = {} } = doc,
      { objects = [], duration } = data,
      object = objects[0] || {},
      { attrs = {} } = object

    const defaultTempObject = {
      ...doc,
      attrs,
      duration,
    }

    // 原子事件
    if (data.function === 'event') {
      eventList.unshift(defaultTempObject)
    } else {
      // 持续性事件
      let target = -1,
        targetEvent = null

      // 是否在当前的eventList中
      for (let i = 0; i < eventList.length; i++) {
        if (eventList[i].data.eventID === data.eventID) {
          target = i
        }
      }

      // 不存在当前的eventList中
      if (target === -1) {
        targetEvent = defaultTempObject
      } else {
        targetEvent = eventList[target]
        eventList.splice(target, 1)
      }

      eventList.unshift(targetEvent)
    }

    return doc
  })

  return eventList
}

// event detail数据整理
export function formatEventDetail(event = {}, docID = null) {
  const { docs = [], handleStatus, muted, eventEnded, duration } = event,
    currentDoc = docs.filter((item) => item.docID === docID)[0] || {}

  const { objects = [] } = currentDoc.data || {},
    object = objects[0] || {},
    { attrs = {} } = object

  currentDoc.objects = formatObjects(objects)

  const newEvent = {
    ...currentDoc,
    attrs,
    handleStatus,
    muted,
    eventEnded,
    duration,
  }

  return newEvent
}

// objects数据修改
export function formatObjects(objects = []) {
  const { defectLayerMapping } = store.state.options

  objects.forEach((item = {}) => {
    const { objClass, objType } = item

    let color = null,
      layer = null

    // all_defect
    if (objClass === 'defect' && defectLayerMapping[ALL_DEFECT]) {
      color = defectLayerMapping[ALL_DEFECT].color
      layer = defectLayerMapping[ALL_DEFECT].key
    }

    // objType mapping
    if (defectLayerMapping[objType] !== undefined) {
      color = defectLayerMapping[objType].color
      layer = defectLayerMapping[objType].key
    }

    item.color = color || 'gray'
    item.layer = layer
  })

  return objects
}

function formatTimeString(time) {
  if (!time) return '00'

  if (time > 9) {
    return time
  } else {
    return `0${time}`
  }
}

// 获取间隔时间，传入单位：秒
export function getEventDuration(dur) {
  if (dur) {
    const duration = moment.duration(dur, 'seconds'),
      seconds = duration.seconds(),
      minutes = duration.minutes(),
      hours = duration.hours()

    const hourStr = formatTimeString(hours),
      minuteStr = formatTimeString(minutes),
      secondsStr = formatTimeString(seconds)

    return `${hourStr}:${minuteStr}:${secondsStr}`
  } else {
    return '-'
  }
}

// 获取options参数值
export function getOptionsParam(data = {}, groupKey, paramKey) {
  const { param_groups = {} } = data || {}
  const groupParam = param_groups[groupKey] || {},
    { params = {} } = groupParam
  const param = params[paramKey] || {}

  return param
}

// 转成defect对应layer
export function formatDefectLayerConfig(config = []) {
  const defectLayerMapping = {}

  config.forEach((item = {}) => {
    const { key, value, color } = item

    if (value) {
      const defects = value.split(',')

      defects.forEach((defect) => {
        if (defect) {
          defectLayerMapping[defect] = {
            layer: key,
            color,
          }
        }
      })
    }
  })

  return defectLayerMapping
}

// 从annotation中获取标注数据
export function getAnnotationData(annotation = {}) {
  const { body = [] } = annotation,
    annotationData = body[0] || {}

  return annotationData.value
}
