import { createStore } from 'vuex'
import common from './common'
import event from './event'
import options from './options'

export default createStore({
  modules: {
    common,
    event,
    options
  },
})
