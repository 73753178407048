import { getLayeringConfig } from '../api/options'
import { getOptionsParam, formatDefectLayerConfig } from '../lib/utils'
import { DEFAULT_DEFECT_LAYER_CONFIG } from '../lib/constant'

const state = {
  defectLayerConfig: [],
  defectLayerMapping: {},
}

const mutations = {
  SET_DATAS(state, { key, value }) {
    state[key] = value
  },
}

const actions = {
  async getLayeringConfig({ commit }, params = {}) {
    const result = await getLayeringConfig(params).catch(() => {})

    if (result) {
      const param = getOptionsParam(result, 'defect', 'defect_layer_config'),
        paramValue = param.value || DEFAULT_DEFECT_LAYER_CONFIG

      commit('SET_DATAS', { key: 'defectLayerConfig', value: paramValue })
      commit('SET_DATAS', {
        key: 'defectLayerMapping',
        value: formatDefectLayerConfig(paramValue)
      })
    }
  },
  // 统一的设置数据
  setDatas({ commit }, data) {
    commit('SET_DATAS', data)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
