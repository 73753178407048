export const ROUTER_WHITE_LIST = ['Login', '403', '404']

export const DEFAULT_PAGINATION = { current: 1, pageSize: 10, total: 0 }

export const DEFAULT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm:ss'

export const ENENT_FUNCTION_MAPPING = {
  eventStart: 'Start',
  eventUpdate: 'Update',
  eventEnd: 'End',
  ended: 'Ended',
}

export const EVENT_NOTIFICATION_STATUS_LIST = [
  {
    key: 'pending',
    label: 'Pending',
    showTotal: true,
    actions: [
      {
        btnText: 'Acknowledge',
        parameter: {
          handleStatus: 'acknowledged',
        },
      },
      {
        btnText: 'Mute',
        type: 'warning',
        parameter: {
          muted: true,
        },
      },
    ],
  },
  {
    key: 'acknowledged',
    label: 'Acknowledged',
    showTotal: true,
    actions: [
      {
        btnText: 'Done',
        parameter: {
          handleStatus: 'done',
        },
      },
      {
        btnText: 'Mute',
        type: 'warning',
        parameter: {
          muted: true,
        },
      },
    ],
  },
  {
    key: 'muted',
    label: 'Muted',
    showTotal: true,
    actions: [
      {
        btnText: 'Unmute',
        parameter: {
          muted: false,
        },
      },
    ],
  },
  {
    key: 'done',
    label: 'Done',
    showTotal: false,
    actions: [
      {
        btnText: 'Mute',
        type: 'warning',
        parameter: {
          muted: true,
        },
      },
    ],
  },
  {
    key: 'ended',
    label: 'Ended',
    showTotal: false,
    actions: [],
  },
]

export const ANNOTATION_TYPE_MAPPING = {
  rect: {
    type: 'rect', // 在annotorious中画框的类型
    xintType: 'rect', // 在xint中数据画框的类型
    drawType: 'rect_drag', // selector类型
  },
  polygon: {
    type: 'polygon',
    xintType: 'polygon',
    drawType: 'polygon',
  },
  dot: {
    // 目前只用于makerjs
    type: 'dot',
    xintType: 'dot',
  },
  line: {
    // 目前只用于makerjs
    type: 'line',
    xintType: 'line',
  },
}

export const ALL_DEFECT = 'all_defect'
export const DEFAULT_DEFECT_LAYER_CONFIG = [
  { key: 1, value: ALL_DEFECT, color: 'red' }
]
